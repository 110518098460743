/*###########################################################################
// Slider products
//#########################################################################*/

import Swiper from "swiper";
import { Navigation, Thumbs } from 'swiper/modules';

const ProductSlider = {
  config: {
    galleryMain: '.product-gallery',
    galleryThumbs: '.product-gallery-thumbs',
    nextButton: '.custom-button-next',
    prevButton: '.custom-button-prev',
    sliderJumper: '.slider-jumper'
  },

  init() {
    this.initThumbs();
    this.initMain();
    this.jumpToPresetType();
    this.initSliderJumper();
  },

  initThumbs() {
    this.thumbsSwiper = new Swiper(this.config.galleryThumbs, {
      modules: [Navigation],
      spaceBetween: 10,
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideClass: 'swiper-slide',
      slideToClickedSlide: true,
      breakpoints: {
        320: {
          slidesPerView: 'auto',
        },
      },
    });

    // Set fixed width for slides
    const slides = document.querySelectorAll(`${this.config.galleryThumbs} .swiper-slide`);
    slides.forEach(slide => {
      slide.style.width = '124px';
    });
  },

  initMain() {
    this.mainSwiper = new Swiper(this.config.galleryMain, {
      modules: [Navigation, Thumbs],
			slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: this.config.nextButton,
        prevEl: this.config.prevButton,
      },
      thumbs: {
        swiper: this.thumbsSwiper
      }
    });
  },

  jumpToPresetType() {
    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get('type');

    if (typeParam) {
      const presetSlide = document.querySelector(`${this.config.galleryThumbs} .swiper-slide[data-preset-type="${typeParam}"]`);
			const parentPresetSlide = document.querySelector(`${this.config.galleryThumbs} .swiper-slide[data-preset-type-parent="${typeParam}"]`);
      if (presetSlide) {
        const slideIndex = Array.from(presetSlide.parentNode.children).indexOf(presetSlide);
        this.thumbsSwiper.slideTo(slideIndex);
        this.mainSwiper.slideTo(slideIndex);
      } else {
				if (parentPresetSlide) {
					const slideIndexParent = Array.from(parentPresetSlide.parentNode.children).indexOf(parentPresetSlide);
					this.thumbsSwiper.slideTo(slideIndexParent);
					this.mainSwiper.slideTo(slideIndexParent);
				}
			}
    }
  },

  initSliderJumper() {
    const jumpers = document.querySelectorAll(this.config.sliderJumper);
    jumpers.forEach(jumper => {
      jumper.addEventListener('click', () => {

        const typeButton = jumper.getAttribute('data-type-button');
        const targetSlide = document.querySelector(`${this.config.galleryThumbs} .swiper-slide[data-preset-type="${typeButton}"]`);
        if (targetSlide) {
          const slideIndex = Array.from(targetSlide.parentNode.children).indexOf(targetSlide);
          this.thumbsSwiper.slideTo(slideIndex);
          this.mainSwiper.slideTo(slideIndex);
        }
      });
    });
  }
};

document.addEventListener('DOMContentLoaded', function() {
  ProductSlider.init();
});
