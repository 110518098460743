/*###########################################################################
// Slider logos
//#########################################################################*/

import Swiper from "swiper";
import { Autoplay } from 'swiper/modules';

const logoSlider = {
  config: {
    sliderLogo: '.slider-logo',
  },

	elements: {},

  init() {
		const self = this

    self.elements.sliderLogos = document.querySelectorAll(self.config.sliderLogo)


    if (self.elements.sliderLogos !== null) {
      for (let i = 0; i < self.elements.sliderLogos.length; i++) {
        const sliderLogo = self.elements.sliderLogos[i]

        self.handleSliderLogo(sliderLogo)
      }
    }
  },

  handleSliderLogo(slider) {
    const self = this

		const swiper = new Swiper(slider, {
			modules: [Autoplay],
			slidesPerView: 2.5,
			spaceBetween: 40,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
			},
			speed: 500,
			loop: true,
			autoHeight: false,
			breakpoints: {
				768: {
					slidesPerView: 4,
				},

				1024: {
					slidesPerView: 6,
				}
			}
		})
  }
};

document.addEventListener('DOMContentLoaded', function() {
  logoSlider.init();
});
