/*###########################################################################
// Slider testimonials
//#########################################################################*/

import Swiper from "swiper"
import { EffectFade } from 'swiper/modules'

const sliderTestimonials = {
  config: {
    sliderTestimonials: '.slider-testimonials',
  },

	elements: {},

  init() {
		const self = this

    self.elements.sliderTestimonials = document.querySelectorAll(self.config.sliderTestimonials)

    if (self.elements.sliderTestimonials !== null) {
      for (let i = 0; i < self.elements.sliderTestimonials.length; i++) {
        const sliderTestimonials = self.elements.sliderTestimonials[i]

        self.handleSliderTestimonials(sliderTestimonials)
      }
    }
  },

  handleSliderTestimonials(slider) {
    const self = this

		const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')

		const swiper = new Swiper(slider, {
			modules: [ EffectFade ],
			slidesPerView: 1,
			spaceBetween: 40,
			effect: "fade",
			speed: 500,
			loop: true,
			autoHeight: true,
		})

		if (prevBtn !== null && nextBtn !== null && swiper && !swiper.destroyed) {
      prevBtn.addEventListener('click', function (e) {
        swiper.slidePrev(500)
      })

      nextBtn.addEventListener('click', function (e) {
        swiper.slideNext(500)
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  sliderTestimonials.init()
})
