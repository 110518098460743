/*###########################################################################
// Slider gallery
//#########################################################################*/

import Swiper from "swiper";
import { Autoplay, EffectFade, Manipulation } from 'swiper/modules';
import { debounce } from '../utilities/_throttleDebounce'

const sliderGallery = {
  config: {
    sliderGallery: '.sliderGallery',
  },

	elements: {},

	data: {
		galleryImages: null,
		galleryContents: null
	},

  init() {
		const self = this

    self.elements.sliderGalleries = document.querySelectorAll(self.config.sliderGallery)

    if (self.elements.sliderGalleries !== null) {
      for (let i = 0; i < self.elements.sliderGalleries.length; i++) {
        const sliderGallery = self.elements.sliderGalleries[i]

        self.handleSliderGallery(sliderGallery)
      }
    }
  },

  handleSliderGallery(sliderGallery) {
    const self = this

		const sliderGalleryLeft = sliderGallery.querySelector('.sliderGalleryLeft')
		const sliderGalleryContentLeft = sliderGallery.querySelector('.sliderGalleryContentLeft')

		const sliderGalleryMiddle = sliderGallery.querySelector('.sliderGalleryMiddle')
		const sliderGalleryContentMiddle = sliderGallery.querySelector('.sliderGalleryContentMiddle')

		const sliderGalleryRight = sliderGallery.querySelector('.sliderGalleryRight')
		const sliderGalleryContentRight = sliderGallery.querySelector('.sliderGalleryContentRight')

		const prevBtn = sliderGallery.parentNode.querySelector('.slider__control--prev')
    const nextBtn = sliderGallery.parentNode.querySelector('.slider__control--next')

		self.data.galleryItems = JSON.parse(sliderGallery.getAttribute('data-gallery-items'))

		let swiperLeft = null
		let swiperContentLeft = null
		let swiperMiddle = null
		let swiperContentMiddle = null
		let swiperRight = null
		let swiperContentRight = null

		const speed = window.innerWidth < 1024 ? 1000 : 1500

		const setupSliders = () => {
			const splitArray = self.splitArrayBasedOnScreenSize()

			for (let i = 0; i < splitArray.length; i++) {
				const arrayChunk = splitArray[i]

				if (i == 0) {
					swiperLeft = new Swiper(sliderGalleryLeft, {
						modules: [Autoplay, Manipulation],
						slidesPerView: 1,
						spaceBetween: 0,
						allowTouchMove: false,
						speed: speed,
						loop: true
					})

					swiperContentLeft = new Swiper(sliderGalleryContentLeft, {
						modules: [EffectFade, Manipulation],
						slidesPerView: 1,
						spaceBetween: 40,
						allowTouchMove: false,
						effect: "fade",
						speed: speed,
						loop: true
					})

					swiperLeft.on('slideChange', function () {
						swiperContentLeft.slideTo(swiperLeft.realIndex, speed)
					})

					swiperLeft.removeAllSlides()
					swiperContentLeft.removeAllSlides()

					for (let j = 0; j < arrayChunk.length; j++) {
						const galleryItem = arrayChunk[j]

						swiperLeft.appendSlide(galleryItem.image)
						swiperContentLeft.appendSlide(galleryItem.content)
					}
				} else if (i == 1) {
					swiperMiddle = new Swiper(sliderGalleryMiddle, {
						modules: [Autoplay, Manipulation],
						slidesPerView: 1,
						spaceBetween: 0,
						allowTouchMove: false,
						speed: speed,
						loop: true
					})

					swiperContentMiddle = new Swiper(sliderGalleryContentMiddle, {
						modules: [EffectFade, Manipulation],
						slidesPerView: 1,
						spaceBetween: 40,
						allowTouchMove: false,
						effect: "fade",
						speed: speed,
						loop: true
					})

					swiperMiddle.on('slideChange', function () {
						swiperContentMiddle.slideTo(swiperMiddle.realIndex, speed)
					})

					swiperMiddle.removeAllSlides()
					swiperContentMiddle.removeAllSlides()

					for (let j = 0; j < arrayChunk.length; j++) {
						const galleryItem = arrayChunk[j]

						swiperMiddle.appendSlide(galleryItem.imageWide)
						swiperContentMiddle.appendSlide(galleryItem.content)
					}
				} else if (i == 2) {
					swiperRight = new Swiper(sliderGalleryRight, {
						modules: [Autoplay, Manipulation],
						slidesPerView: 1,
						spaceBetween: 0,
						allowTouchMove: false,
						speed: speed,
						loop: true
					})

					swiperContentRight = new Swiper(sliderGalleryContentRight, {
						modules: [EffectFade, Manipulation],
						slidesPerView: 1,
						spaceBetween: 40,
						allowTouchMove: false,
						effect: "fade",
						speed: speed,
						loop: true
					})

					swiperRight.on('slideChange', function () {
						swiperContentRight.slideTo(swiperRight.realIndex, speed)
					})

					swiperRight.removeAllSlides()
					swiperContentRight.removeAllSlides()

					for (let j = 0; j < arrayChunk.length; j++) {
						const galleryItem = arrayChunk[j]

						swiperRight.appendSlide(galleryItem.image)
						swiperContentRight.appendSlide(galleryItem.content)
					}
				}
			}
		}

		setupSliders()

		const setupControls = () => {
			prevBtn.addEventListener('click', function (e) {
				swiperLeft.slidePrev(speed)

				if (swiperMiddle !== null) {
					swiperMiddle.slidePrev(speed)
				}
				if (swiperRight !== null) {
					swiperRight.slidePrev(speed)
				}
      })

      nextBtn.addEventListener('click', function (e) {
				swiperLeft.slideNext(speed)

				if (swiperMiddle !== null) {
					swiperMiddle.slideNext(speed)
				}
				if (swiperRight !== null) {
					swiperRight.slideNext(speed)
				}
      })
		}

		if (prevBtn !== null && nextBtn !== null) {
      setupControls()
    }

		let width = window.innerWidth
		window.addEventListener('resize', debounce(function() {
			if (window.innerWidth !== width) {
				window.location.reload()
				width = window.innerWidth
			}
		}, 250))

		const initEvent = new Event('sliderGalleryInitialised')
		window.dispatchEvent(initEvent)
  },

	splitArrayBasedOnScreenSize() {
		const self = this

		const screenWidth = window.innerWidth;

		let numOfSliders = 1;
		if (screenWidth >= 1024) {
			numOfSliders = 3;
		} else if (screenWidth >= 768) {
			numOfSliders = 2;
		}

		const splitArrays = [];
		const itemsPerSlider = Math.ceil(self.data.galleryItems.length / numOfSliders);

		for (let i = 0; i < numOfSliders; i++) {
			splitArrays.push(self.data.galleryItems.slice(i * itemsPerSlider, (i + 1) * itemsPerSlider));
		}

		return splitArrays;
	},
}

window.addEventListener('load', function() {
  sliderGallery.init()
})
