/*###########################################################################
// Products
//#########################################################################*/

const products = {
	config: {
		productNavFilters: '.product__nav__filters',
		productNavMobile: '.product__nav__mobile'
	},

	elements: {},

	init() {
		const self = this

		self.elements.productNavFilters = document.querySelector(self.config.productNavFilters)

		if (self.elements.productNavFilters !== null) {
			self.handleProductNavFilters()
		}

		self.elements.productNavMobile = document.querySelector(self.config.productNavMobile)

		if (self.elements.productNavMobile !== null) {
			self.handleProductNavMobile()
		}

		if (typeof htmx !== 'undefined') {
			// After swap re-add event listeners
			//
			htmx.on('htmx:afterSwap', function (event) {
				self.elements.productNavFilters = document.querySelector(self.config.productNavFilters)

				if (self.elements.productNavFilters !== null) {
					self.handleProductNavFilters()
				}

				self.elements.productNavMobile = document.querySelector(self.config.productNavMobile)

				if (self.elements.productNavMobile !== null) {
					self.handleProductNavMobile()
				}
			})
		}
	},

	handleProductNavFilters() {
		const self = this

		const filterLooseCover = self.elements.productNavFilters.querySelector('.checkbox input[name="looseCover"]')
		const filterLeather = self.elements.productNavFilters.querySelector('.checkbox input[name="leather"]')

		if (filterLooseCover.checked) {
			filterLeather.parentNode.classList.add('is-disabled')
		} else {
			filterLeather.parentNode.classList.remove('is-disabled')
		}

		filterLooseCover.addEventListener('change', function() {
			if (filterLooseCover.checked) {
				filterLeather.parentNode.classList.add('is-disabled')
			} else {
				filterLeather.parentNode.classList.remove('is-disabled')
			}
		})

		if (filterLeather.checked) {
			filterLooseCover.parentNode.classList.add('is-disabled')
		} else {
			filterLooseCover.parentNode.classList.remove('is-disabled')
		}

		filterLeather.addEventListener('change', function() {
			if (filterLeather.checked) {
				filterLooseCover.parentNode.classList.add('is-disabled')
			} else {
				filterLooseCover.parentNode.classList.remove('is-disabled')
			}
		})
	},

	handleProductNavMobile() {
		const self = this

		self.elements.productNavMobile.addEventListener('click', function() {
			self.elements.productNavMobile.parentNode.classList.toggle('is-open')
		})
	}
}

window.addEventListener('load', function () {
	products.init()
})
