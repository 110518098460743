/*###########################################################################
// Hero
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'

const heroMedia = {
	config: {
		heroMedia: '.heroMedia',
		topRight: '.heroMedia__tr',
		bottomRight: '.heroMedia__br',
		bottomRow: '.heroMedia__bottomRow'
	},

	elements: {},

	init() {
		const self = this

		self.elements.heroMedia = document.querySelectorAll(self.config.heroMedia)

		if (self.elements.heroMedia !== null) {
			for (let i = 0; i < self.elements.heroMedia.length; i++) {
				const heroMedia = self.elements.heroMedia[i]

				self.handleHeroMedia(heroMedia)
			}
		}
	},

	handleHeroMedia(hero) {
		const self = this
		const topRight = hero.querySelector(self.config.topRight)
		const bottomRight = hero.querySelector(self.config.bottomRight)
		const bottomRow = hero.querySelector(self.config.bottomRow)
		let offset = -(topRight.clientWidth - bottomRight.clientWidth) - 10 + 'px'

		if (window.innerWidth < 414 || window.innerWidth >= 640 && window.innerWidth < 768) {
			offset = -(topRight.clientWidth - bottomRight.clientWidth) + 'px'
		}

		if(window.innerWidth < 768){
			bottomRow.style.transform = 'translateX(' + offset + ') translateY(-25%)'
		}else{
			bottomRow.style.transform = ""
		}

		window.addEventListener('resize', throttle(function () {
			if (window.innerWidth < 768) {
				offset = -(topRight.clientWidth - bottomRight.clientWidth) - 10 + 'px'

				if (window.innerWidth < 414 || window.innerWidth >= 640 && window.innerWidth < 768) {
					offset = -(topRight.clientWidth - bottomRight.clientWidth) + 'px'
				}

			 	bottomRow.style.transform = 'translateX(' + offset + ') translateY(-25%)'
			}else{
				bottomRow.style.transform = ""
			}
		}, 100))

	}
}

window.addEventListener('load', function () {
	heroMedia.init()
})
