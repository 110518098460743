/*###########################################################################
// Modals
//#########################################################################*/

const modals = {
	initialised: false,

	elements: {
		modalTriggers: null,
	},

	init() {
		const self = this

		self.initModalTriggers()

		window.addEventListener('sliderGalleryInitialised', function (event) {
			setTimeout(function() {
				console.log('init');

				self.initModalTriggers()
			}, 100)
		})

		if (typeof htmx !== 'undefined') {
			// After swap re-add event listeners
			//
			htmx.on('htmx:afterSwap', function (event) {
				self.initModalTriggers()
			})
		}

		self.elements.modalFormMessages = document.querySelectorAll('.modal__form')
		if (self.elements.modalFormMessages !== null) {
			for (let i = 0; i < self.elements.modalFormMessages.length; i++) {
				const modalForm = self.elements.modalFormMessages[i]
				const targetModal = document.querySelector(modalForm.getAttribute('data-modal'))

				if (targetModal !== null) {
					modalForm.addEventListener('onAfterFormieSubmit', function (e) {
						e.preventDefault()

						self.handleOpen(targetModal, modalForm)
					})
				} else {
					console.error('Unable to find target modal for modal form.')
				}
			}
		}
	},

	initModalTriggers() {
		const self = this

		self.elements.modalTriggers = document.querySelectorAll('.modal__trigger')

		if (self.elements.modalTriggers !== null) {
			for (let i = 0; i < self.elements.modalTriggers.length; i++) {
				const modalTrigger = self.elements.modalTriggers[i]
				const targetModal = document.querySelector(modalTrigger.getAttribute('data-modal'))

				if (targetModal !== null) {
					modalTrigger.addEventListener('click', function (e) {
						if (modalTrigger.classList.contains('modal__trigger--desktop-only')) {
							if (window.innerWidth >= 1024) {
								e.preventDefault()

								if (!e.target.classList.contains('responsive-video__play-pause')) {
									self.handleOpen(targetModal, modalTrigger)
								}
							}
						}	else if (modalTrigger.classList.contains('modal__trigger--tablet-up')) {
							if (window.innerWidth >= 768) {
								e.preventDefault()

								if (!e.target.classList.contains('responsive-video__play-pause')) {
									self.handleOpen(targetModal, modalTrigger)
								}
							}
						} else {
							e.preventDefault()

							if (!e.target.classList.contains('responsive-video__play-pause')) {
								self.handleOpen(targetModal, modalTrigger)
							}
						}
					})
				} else {
					console.error('Unable to find target modal for modal trigger.')
				}
			}
		}
	},

	handleOpen(modal, modalTrigger) {
		const self = this

		// Open modal
		//
		modal.classList.add('open')

		// Lock body scroll
		//
		document.documentElement.style.overflow = 'hidden'

		// Handle dynamic modal
    //
    if (modal.classList.contains('modal--dynamic') && modalTrigger.getAttribute('data-modal-content') !== '') {
      self.handleDynamicModalOpen(modal, modalTrigger.getAttribute('data-modal-content'))
    }

		// Handle close
		//
		self.handleClose(modal)
	},

	handleClose(modal) {
		const self = this

		const modalClose = modal.querySelector('.modal__close-btn')
		modalClose.addEventListener('click', function (e) {
			self.closeModal(modal)
		})

		const modalOverlay = modal.querySelector('.modal__overlay')
		modalOverlay.addEventListener('click', function (e) {
			self.closeModal(modal)
		})
	},

	closeModal(modal) {
		// Close modal
		//
		modal.classList.remove('open')

		// Unlock scroll
		//
		document.documentElement.style.overflow = ''

		setTimeout(function () {
			// Clear form modal scroll
			//
			modal.scrollTop = 0
		}, 300)
	},

	handleDynamicModalOpen(modal, modalContent) {
    const self = this

    modal.querySelector('.modal__content__inner').innerHTML = JSON.parse(modalContent)
  }
}

window.addEventListener('load', function () {
	modals.init()
})
