/*###########################################################################
// Hero home
//#########################################################################*/

const heroHome = {
	config: {
		heroHome: '.heroHome',
		pin: '.heroHome__pin',
		slides: '.heroHome__slides',
		slideItem: '.heroHome__slide'
	},

	elements: {},

	state: {
		currentSlide: 0,
		speed: 4000,
		isPaused: false,
		time: 0
	},

	timer: null,

	init() {
		const self = this

		self.elements.heroHome = document.querySelector(self.config.heroHome)

		if (self.elements.heroHome !== null) {
			self.state.speed = parseInt(self.elements.heroHome.getAttribute('data-speed'))
			document.head.insertAdjacentHTML("beforeend", `<style>.heroHome__slide.is-active .heroHome__slide__progress__bar { animation: heroHomeProgress ${(self.state.speed/1000)}s forwards ease-in-out !important; }</style>`)

			self.handleHeroHome()

			self.elements.pins = document.querySelectorAll(self.config.pin)
			self.elements.slides = document.querySelector(self.config.slides)
			self.elements.slideItems = document.querySelectorAll(self.config.slideItem)
			for (let i = 0; i < self.elements.pins.length; i++) {
				const pin = self.elements.pins[i]

				self.handleNavigation(pin)
			}

			// Handle initial slide
			//
			const slideItemContent = self.elements.slideItems[self.state.currentSlide].querySelector('.heroHome__slide__content')
			self.elements.slideItems[self.state.currentSlide].style.width = self.elements.slideItems[self.state.currentSlide].clientWidth + slideItemContent.scrollWidth + 'px'
		}
	},

	handleHeroHome() {
		const self = this

		self.state.isPaused = false
		self.state.time = 0

		window.setInterval(function() {
			self.state.time++
		}, 1000)

		self.setupTimer()
	},

	setupTimer() {
		const self = this

		self.timer = setInterval(function() {
			if (self.elements.slideItems.length-1 == self.state.currentSlide) {
				self.state.currentSlide = 0
			} else {
				self.state.currentSlide++
			}

			// Update slide
			//
			self.updateSlide()
		}, self.state.speed)
	},

	updateSlide() {
		const self = this

		// Reset slides
		//
		self.resetSlides()

		// If last then reset to start
		// else set next slide
		//
		if (self.elements.slideItems.length-1 == self.state.currentSlide) {
			self.elements.slideItems[self.state.currentSlide].classList.add('is-active')
			self.elements.pins[self.state.currentSlide].classList.add('is-active')

			const slideItemContent = self.elements.slideItems[self.state.currentSlide].querySelector('.heroHome__slide__content')
			self.elements.slideItems[self.state.currentSlide].style.width = self.elements.slideItems[self.state.currentSlide].clientWidth + slideItemContent.scrollWidth + 'px'
		} else {
			self.elements.slideItems[self.state.currentSlide].classList.add('is-active')
			self.elements.pins[self.state.currentSlide].classList.add('is-active')

			const slideItemContent = self.elements.slideItems[self.state.currentSlide].querySelector('.heroHome__slide__content')
			self.elements.slideItems[self.state.currentSlide].style.width = self.elements.slideItems[self.state.currentSlide].clientWidth + slideItemContent.scrollWidth + 'px'
		}

		// Scroll to slide if mobile
		//
		if (window.innerWidth < 1024) {
			setTimeout(function() {
				self.scrollIntoViewHorizontally(self.elements.slides, self.elements.slideItems[self.state.currentSlide])
			}, 500)
		}
	},

	scrollIntoViewHorizontally(container, child) {
		// Scroll the container such that the child is aligned to the left of the container
    container.scrollLeft = child.offsetLeft
	},

	resetSlides() {
		const self = this

		// Reset slides
		//
		for (let i = 0; i < self.elements.slideItems.length; i++) {
			const slideItem = self.elements.slideItems[i]

			slideItem.style.width = ''

			if (slideItem.classList.contains('is-active')) {
				slideItem.classList.remove('is-active')
			}
		}

		// Reset pins
		//
		for (let i = 0; i < self.elements.pins.length; i++) {
			const pin = self.elements.pins[i]

			if (pin.classList.contains('is-active')) {
				pin.classList.remove('is-active')
			}
		}
	},

	handleNavigation(pin) {
		const self = this

		const targetSlide = document.querySelector(pin.getAttribute('data-slide'))

		pin.addEventListener('click', function() {
			self.goToSlide(pin, targetSlide)
		})

		targetSlide.addEventListener('click', function() {
			self.goToSlide(pin, targetSlide)
		})
	},

	goToSlide(pin, targetSlide) {
		const self = this

		const slideItemContent = targetSlide.querySelector('.heroHome__slide__content')

		if (parseInt(pin.getAttribute('data-pin-no')) !== self.state.currentSlide) {
			pin.style.pointerEvents = 'none'
			clearInterval(self.timer)
			self.timer = null

			setTimeout(function() {
				if (self.timer === null) {
					self.setupTimer()
				}
				pin.style.pointerEvents = 'auto'
			}, 2000)

			// Reset slides
			//
			self.resetSlides()

			// Make pin active
			//
			pin.classList.add('is-active')

			// Make clicked item show
			//
			targetSlide.classList.add('is-active')
			targetSlide.style.width = targetSlide.clientWidth + slideItemContent.scrollWidth + 'px'
			self.state.currentSlide = parseInt(targetSlide.getAttribute('data-slide-no'))

			// Scroll to slide if mobile
			//
			if (window.innerWidth < 1024) {
				setTimeout(function() {
					self.scrollIntoViewHorizontally(self.elements.slides, self.elements.slideItems[self.state.currentSlide])
				}, 500)
			}
		}
	}
}

window.addEventListener('load', function () {
	heroHome.init()
})
