/*###########################################################################
// Sprig filter
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const sprigFilter = {
  config: {
    sprigFilter: '.sprigFilter',
		loadMore: '.loadMore'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sprigFilter = document.querySelector(self.config.sprigFilter)

    if (self.elements.sprigFilter !== null) {
      if (typeof htmx !== 'undefined') {
        // Add loading state
        //
        htmx.on('htmx:beforeRequest', function (event) {
          document.querySelector('#entry-container').classList.add('is-loading')
        })

        // After swap re-add event listeners for the above and remove loading state
        //
        htmx.on('htmx:afterSwap', function (event) {
          document.querySelector('#entry-container').classList.remove('is-loading')

          // Scroll to entries
          self.scrollToContainer()
        })
      }
    }

		self.elements.loadMore = document.querySelector(self.config.loadMore)

		if (self.elements.loadMore !== null) {
			if (typeof htmx !== 'undefined') {
        // Add loading state
        //
        htmx.on('htmx:beforeRequest', function (event) {
          document.querySelector('#entry-container').classList.add('is-loading')
					document.querySelector('#entry-container').parentNode.classList.add('is-loading')
        })

        // After swap re-add event listeners for the above and remove loading state
        //
        htmx.on('htmx:afterSwap', function (event) {
          document.querySelector('#entry-container').classList.remove('is-loading')
					document.querySelector('#entry-container').parentNode.classList.remove('is-loading')
        })
      }
		}
  },

  scrollToContainer() {
		const self = this

    const offsetAmount = document.querySelector('.header').clientHeight + self.elements.sprigFilter.clientHeight
    scrollTo(document.querySelector('#entry-container'), -offsetAmount)
  }

}

window.addEventListener('load', function () {
  sprigFilter.init()
})
